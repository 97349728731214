<template>
  <div>
    <v-card>
      <v-card-title>
        Approved Commission Payments
      </v-card-title>
      <v-card-subtitle>
        View, Manage <code>Invoices</code>
      </v-card-subtitle>
      <v-card-text>
        <v-sheet class="d-flex mt-4">
          <v-sheet max-width="250px" class="mr-5">
            <leben-date-picker label="Month" v-model="filters.month" type="month" @input="filter()" />
          </v-sheet>
          <v-btn class="ml-auto hidden-xs-only" @click="resetFilters">Clear</v-btn>
        </v-sheet>
        <v-sheet>
          <v-data-table
              :headers="headers.filter(header => header.visibleFor === role || role === 'admin')" flat
              :items="invoices.data"
              :items-per-page="10" hide-default-footer>
            <template v-slot:item.user="{item}">
              <div v-if="item.user">
                <h1 class="mb-0 text-xl-h6">{{item.user.full_name}}</h1>
                <p class="ma-0"><small>{{item.user.email}}</small></p>
              </div>
              <div v-else>
                <v-chip label small>None</v-chip>
              </div>
            </template>

            <template v-slot:item.amount="{item}">
              <v-chip v-bind="attrs"
                      v-on="on"
                      :color="item.amount > 0 ? 'success' : 'warning'">
                {{item.amount}} €
              </v-chip>
            </template>
          </v-data-table>
          <v-pagination
              v-model="page"
              @input="paginationChange"
              :length="filters.last_page"
              :total-visible="6"
          ></v-pagination>
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SelectAssigneeDropdown from "@/components/forms/SelectAssigneeDropdown.vue";
import SelectCustomerDropdown from "@/components/forms/SelectCustomerDropdown.vue";
import invoices from "@/api/modules/invoices";
import {mapGetters} from "vuex";
import LebenDatePicker from "../../../../components/LebenDatePicker";

export default {
  name: "CaretakerFee",
  components: {SelectAssigneeDropdown, SelectCustomerDropdown, LebenDatePicker},
  data() {
    return {
      filters: {
        month: null,
        page: 1,
      },
      page: 1,
      headers: [
        {text: 'Month', value: 'month', visibleFor: 'admin', sortable: false},
        {text: 'Full Name', value: 'user', visibleFor: 'admin', sortable: false},
        {text: 'Role', value: 'role', visibleFor: 'admin', sortable: false},
        {text: 'Amount to Charge', value: 'amount', visibleFor: 'admin', sortable: false},
      ],
      invoices: [],
    }
  },
  created: function () {
    this.fetchInvoices()
  },
  computed: {
    ...mapGetters('auth', ['role']),
  },
  methods: {
    fetchInvoices: function () {
      const filters = {
        month: this.filters.month ? `${this.filters.month}-01` : null,
        page: this.page
      }
      invoices.fetchPayouts(filters).then(res => {
        this.invoices = res.data;
        this.filters.last_page = res.data.meta.last_page
      })
    },
    paginationChange: function () {
      this.filters.page = this.page;
      this.fetchInvoices();
    },
    filter: function () {
      this.fetchInvoices()
    },
    resetFilters: function () {
      this.filters = {
        month: null,
        page: 1,
        last_page: null
      };

      this.fetchInvoices()
    },
  },
}
</script>